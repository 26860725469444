<template>
  <div class="about flex flexC">
      <div class="tc p20 bgGray" style="padding: 50px 10%; background: #3669F3; color: #fff; background-image: linear-gradient(-30deg, #3669F3, #003f91); text-shadow: 3px 3px 3px #000;">
        <p class="fb f24">关于我们</p>
        <div class="f14 lh25 mt20 fb">
          我们是一群由80后组成的IT开发团队，都曾在一线互联网公司任职，<br />
          曾任职的公司有高德地图、百度、360、去哪儿网、优酷、vipKid、美菜网等等。<br />
          通过码农互助的形式，为公司、为社会发挥自己的余热。<br />
          提高生产效率、快速便捷的服务是我们一直追求的。
        </div>
      </div>

      <div class="pl20 pr20 pt20 tc bgGray">
        <p class="fb f24 mb20 pt20 mt20">我们的优势</p>
        <p class="f14">设计好、费用低、可落地、有保障</p>
      </div>
      <div class="flex bgGray pl20 pr20 pb20">
        <div class="flex1 bgWhite m20 p20 br10 shadow5">
              <p class="title mt10 mb10 f20 mb20 bgGray br10 p10">原创策划</p>
              <div class="flex mt20">
                <img width="30" height="30" src="../assets/pic4_1.png">
                <div class="ml20">
                  <p class="f14 fb">完整的营销体系</p>
                  <div class="f12">为客户创造更加专业、简明、高效的操作解决方案</div>
                </div>
              </div>
              <div class="flex mt20">
                <img width="30" height="30" src="../assets/pic4_2.png">
                <div class="ml20">
                  <p class="f14 fb">专业策划团队</p>
                  <div class="f12">拥有多年网络营销经验来自一线互联网团队</div>
                </div>
              </div>
              <div class="flex mt20">
                <img width="30" height="30" src="../assets/pic4_3.png">
                <div class="ml20">
                  <p class="f14 fb">多渠道支持</p>
                  <div class="f12">今日头条，百度推广，朋友圈广告，新浪，搜狐...</div>
                </div>
              </div>
              <div class="flex mt20">
                <img width="30" height="30" src="../assets/pic4_4.png">
                <div class="ml20">
                  <p class="f14 fb">KPL考核制</p>
                  <div class="f12">按流量付费</div>
                </div>
              </div>
        </div>
        <div class="flex1 bgWhite m20 p20 br10 shadow5">
              <p class="title mt10 mb10 f20 mb20 bgGray br10 p10">技术实力</p>
              <div class="flex mt20">
                <img width="30" height="30" src="../assets/pic4_5.png">
                <div class="ml20">
                  <p class="f14 fb">技术团队</p>
                  <div class="f12">拥有近80人一线互联网公司研发人员，涵盖Java、Php、H5、IOS、小程序、安卓开发等各类专家级的工程师</div>
                </div>
              </div>
              <div class="flex mt20">
                <img width="30" height="30" src="../assets/pic4_6.png">
                <div class="ml20">
                  <p class="f14 fb">多项技术突破</p>
                  <div class="f12">拥有自己的组件库、高难度定制化开发功能</div>
                </div>
              </div>
              <div class="flex mt20">
                <img width="30" height="30" src="../assets/pic4_7.png">
                <div class="ml20">
                  <p class="f14 fb">专配数据安全专家</p>
                  <div class="f12">数据安全是我们最为重视的，我们有专家级别的安全工程师维护</div>
                </div>
              </div>
              <div class="flex mt20">
                <img width="30" height="30" src="../assets/pic4_8.png">
                <div class="ml20">
                  <p class="f14 fb">丰富的产品线</p>
                  <div class="f12">挖掘各行各业信息，为近百个公司提供开发及支持，具有很强的针对性和实用性</div>
                </div>
              </div>
        </div>
        <div class="flex1 bgWhite m20 p20 br10 shadow5">
              <p class="title mt10 mb10 f20 mb20 bgGray br10 p10">售后服务</p>
              <div class="flex mt20">
                <img width="30" height="30" src="../assets/pic4_9.png">
                <div class="ml20">
                  <p class="f14 fb">7*24小时响应式服务</p>
                  <div class="f12">我们通过微信、QQ、电话和售后工单的方式，全方位为客户解决各种操作问题、技术问题</div>
                </div>
              </div>
              <div class="flex mt20">
                <img width="30" height="30" src="../assets/pic4_10.png">
                <div class="ml20">
                  <p class="f14 fb">服务评价回访机制</p>
                  <div class="f12">自建服务评价体系，我们跟踪回访系统深度了解客户需求，追求完美</div>
                </div>
              </div>
              <div class="flex mt20">
                <img width="30" height="30" src="../assets/pic4_11.png">
                <div class="ml20">
                  <p class="f14 fb">售后团队</p>
                  <div class="f12">一对一专项技术支持服务</div>
                </div>
              </div>
              <div class="flex mt20">
                <img width="30" height="30" src="../assets/pic4_12.png">
                <div class="ml20">
                  <p class="f14 fb">售后投诉监督</p>
                  <div class="f12">通过设立微信，用于投诉和电话投诉，由专人来监督售后服务质量问题</div>
                </div>
              </div>
        </div>
      </div>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'About',
  components: {
  },

}
</script>
